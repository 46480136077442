/* eslint-disable linebreak-style */
import React from 'react';

const speedyNodeIcon = (
    fill: string,
    size: number,
    style?: React.CSSProperties,
) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_359_27730)" style={style}>
            <path
                d="M14.2947 20.5122C14.1464 20.5132 14.0004 20.4753 13.8713 20.4023L7.01273 16.445C6.88352 16.3705 6.77631 16.2633 6.70196 16.1341C6.62762 16.0049 6.58877 15.8584 6.58936 15.7094V7.80328C6.58877 7.65428 6.62762 7.50777 6.70196 7.37859C6.77631 7.2494 6.88352 7.14213 7.01273 7.06763L13.8544 3.11036C13.9831 3.03614 14.1291 2.99707 14.2778 2.99707C14.4264 2.99707 14.5724 3.03614 14.7011 3.11036L21.5767 7.07609C21.7059 7.15059 21.8131 7.25786 21.8874 7.38704C21.9618 7.51623 22.0006 7.66274 22 7.81174V15.7178C22.0006 15.8668 21.9618 16.0133 21.8874 16.1425C21.8131 16.2717 21.7059 16.379 21.5767 16.4535L14.7011 20.4023C14.5771 20.4725 14.4373 20.5103 14.2947 20.5122ZM8.28284 15.2274L14.2947 18.6942L20.3066 15.2274V8.28525L14.2947 4.81841L8.28284 8.28525V15.2274Z"
                fill={fill}
            />
            <path
                d="M14.2947 12.6061C14.1456 12.6046 13.9995 12.5637 13.8713 12.4877L7.01269 8.53047C6.88437 8.45614 6.77787 8.34945 6.70383 8.22109C6.62979 8.09272 6.59082 7.94719 6.59082 7.79905C6.59082 7.65092 6.62979 7.50538 6.70383 7.37702C6.77787 7.24865 6.88437 7.14196 7.01269 7.06763L13.8543 3.11036C13.9831 3.03614 14.1291 2.99707 14.2777 2.99707C14.4264 2.99707 14.5724 3.03614 14.7011 3.11036L21.5766 7.07609C21.7049 7.15042 21.8114 7.25711 21.8855 7.38547C21.9595 7.51384 21.9985 7.65937 21.9985 7.80751C21.9985 7.95564 21.9595 8.10118 21.8855 8.22954C21.8114 8.35791 21.7049 8.4646 21.5766 8.53893L14.7011 12.4877C14.5778 12.5609 14.438 12.6017 14.2947 12.6061ZM9.12954 7.80328L14.2947 10.7797L19.4598 7.80328L14.2947 4.81841L9.12954 7.80328Z"
                fill={fill}
            />
            <path
                d="M14.2946 20.5123C14.07 20.5123 13.8547 20.4232 13.6959 20.2646C13.5371 20.106 13.4479 19.891 13.4479 19.6667V11.7606C13.4479 11.5364 13.5371 11.3213 13.6959 11.1627C13.8547 11.0041 14.07 10.915 14.2946 10.915C14.5192 10.915 14.7346 11.0041 14.8934 11.1627C15.0521 11.3213 15.1414 11.5364 15.1414 11.7606V19.6667C15.1414 19.891 15.0521 20.106 14.8934 20.2646C14.7346 20.4232 14.5192 20.5123 14.2946 20.5123Z"
                fill={fill}
            />
            <path
                d="M14.2947 12.6057C14.1072 12.607 13.9246 12.5461 13.7755 12.4326C13.6264 12.3191 13.5192 12.1594 13.4708 11.9785C13.4223 11.7977 13.4354 11.6059 13.5079 11.4332C13.5804 11.2605 13.7082 11.1168 13.8713 11.0245L19.4598 7.80283L13.8544 4.5812C13.659 4.46907 13.5162 4.28403 13.4575 4.06678C13.3987 3.84953 13.4288 3.61788 13.5411 3.42277C13.6534 3.22767 13.8387 3.08509 14.0562 3.02642C14.2738 2.96775 14.5058 2.99778 14.7011 3.10991L21.5767 7.07564C21.705 7.14997 21.8115 7.25666 21.8855 7.38502C21.9596 7.51339 21.9985 7.65893 21.9985 7.80706C21.9985 7.9552 21.9596 8.10073 21.8855 8.2291C21.8115 8.35746 21.705 8.46415 21.5767 8.53848L14.7011 12.4873C14.5779 12.5605 14.438 12.6012 14.2947 12.6057Z"
                fill={fill}
            />
            <path
                d="M3.18543 11.9881C2.99794 11.9894 2.81533 11.9286 2.66621 11.8151C2.5171 11.7016 2.40993 11.5418 2.3615 11.361C2.31307 11.1801 2.32613 10.9883 2.39862 10.8156C2.47111 10.643 2.59894 10.4992 2.76206 10.4069L3.78661 9.81499C3.98199 9.70286 4.21396 9.67282 4.43151 9.7315C4.64906 9.79017 4.83436 9.93274 4.94664 10.1279C5.05893 10.323 5.089 10.5546 5.03025 10.7719C4.97149 10.9891 4.82873 11.1742 4.63335 11.2863L3.6088 11.8697C3.48057 11.9457 3.33452 11.9866 3.18543 11.9881Z"
                fill={fill}
            />
            <path
                d="M3.18543 15.3709C2.99794 15.3722 2.81533 15.3114 2.66621 15.1979C2.5171 15.0844 2.40993 14.9247 2.3615 14.7438C2.31307 14.5629 2.32613 14.3711 2.39862 14.1984C2.47111 14.0258 2.59894 13.882 2.76206 13.7897L3.78661 13.1978C3.98199 13.0857 4.21396 13.0556 4.43151 13.1143C4.64906 13.173 4.83436 13.3156 4.94664 13.5107C5.05893 13.7058 5.089 13.9374 5.03025 14.1547C4.97149 14.3719 4.82873 14.557 4.63335 14.6691L3.6088 15.2525C3.48057 15.3285 3.33452 15.3694 3.18543 15.3709Z"
                fill={fill}
            />
            <path
                d="M5.92886 20.7822C5.78109 20.7813 5.63613 20.7419 5.50839 20.6677C5.38064 20.5935 5.27455 20.4872 5.20066 20.3594C5.14433 20.2634 5.10752 20.1573 5.09235 20.047C5.07719 19.9368 5.08396 19.8247 5.11227 19.717C5.14059 19.6094 5.1899 19.5084 5.25738 19.4199C5.32486 19.3313 5.40917 19.257 5.50549 19.201L6.68246 18.5246C6.77883 18.4634 6.8866 18.4223 6.99929 18.4038C7.11197 18.3853 7.22724 18.3897 7.33816 18.4169C7.44908 18.444 7.55336 18.4932 7.64473 18.5617C7.73609 18.6301 7.81265 18.7163 7.8698 18.815C7.92695 18.9137 7.96351 19.023 7.97728 19.1362C7.99105 19.2494 7.98175 19.3642 7.94993 19.4737C7.91811 19.5833 7.86443 19.6852 7.79213 19.7735C7.71983 19.8618 7.63039 19.9345 7.5292 19.9874L6.35223 20.6639C6.22401 20.7399 6.07796 20.7807 5.92886 20.7822Z"
                fill={fill}
            />
            <path
                d="M9.29046 22.0001C9.10495 22.0001 8.92459 21.9392 8.77711 21.8268C8.62962 21.7144 8.52317 21.5568 8.47411 21.3781C8.42506 21.1995 8.43611 21.0097 8.50558 20.8379C8.57505 20.6661 8.69908 20.5219 8.85862 20.4274L9.22272 20.216C9.31909 20.1548 9.42687 20.1137 9.53955 20.0952C9.65224 20.0767 9.76751 20.0811 9.87843 20.1083C9.98934 20.1354 10.0936 20.1847 10.185 20.2531C10.2763 20.3215 10.3529 20.4077 10.4101 20.5064C10.4672 20.6051 10.5038 20.7144 10.5175 20.8276C10.5313 20.9408 10.522 21.0556 10.4902 21.1652C10.4584 21.2747 10.4047 21.3766 10.3324 21.4649C10.2601 21.5532 10.1707 21.6259 10.0695 21.6788L9.70536 21.8902C9.57881 21.9618 9.43592 21.9997 9.29046 22.0001Z"
                fill={fill}
            />
            <path
                d="M2.8468 18.99C2.65932 18.9913 2.4767 18.9304 2.32759 18.8169C2.17848 18.7034 2.07131 18.5437 2.02288 18.3628C1.97445 18.1819 1.9875 17.9901 2.05999 17.8175C2.13248 17.6448 2.26031 17.501 2.42343 17.4087L4.60802 16.1573C4.80142 16.0463 5.03085 16.0161 5.24647 16.0731C5.46209 16.1301 5.64648 16.2697 5.75958 16.4617C5.81592 16.5577 5.85273 16.6639 5.86789 16.7741C5.88306 16.8843 5.87629 16.9965 5.84797 17.1041C5.81966 17.2117 5.77035 17.3127 5.70287 17.4013C5.63539 17.4898 5.55108 17.5642 5.45476 17.6201L3.27017 18.9139C3.13796 18.9752 2.99213 19.0014 2.8468 18.99Z"
                fill={fill}
            />
        </g>
        <defs>
            <clipPath id="clip0_359_27730">
                <rect
                    width="20"
                    height="19"
                    fill="white"
                    transform="translate(2 3)"
                />
            </clipPath>
        </defs>
    </svg>
);
export default speedyNodeIcon;
