import React from 'react';

const downloadCloudIcon = (
    fill: string,
    size: number,
    style?: React.CSSProperties,
) => (
    <svg
        aria-hidden="true"
        data-testid="test-icon"
        fill="none"
        height={size}
        viewBox="0 0 24 24"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
        style={style}
    >
        <title>download cloud icon</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.08162 15.437C8.40688 15.1247 8.93424 15.1247 9.2595 15.437L12.0021 18.0698L14.7447 15.437C15.07 15.1247 15.5973 15.1247 15.9226 15.437C16.2479 15.7492 16.2479 16.2554 15.9226 16.5677L12.591 19.7658C12.2658 20.0781 11.7384 20.0781 11.4132 19.7658L8.08162 16.5677C7.75636 16.2554 7.75636 15.7492 8.08162 15.437Z"
            fill={fill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0021 11.2051C12.4621 11.2051 12.835 11.5631 12.835 12.0047V19.2005C12.835 19.642 12.4621 20 12.0021 20C11.5421 20 11.1692 19.642 11.1692 19.2005V12.0047C11.1692 11.5631 11.5421 11.2051 12.0021 11.2051Z"
            fill={fill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.22658 4.00465C10.3597 3.96553 11.4873 4.17369 12.5245 4.61345C13.5617 5.05321 14.4815 5.71311 15.2146 6.54348C15.8063 7.21377 16.264 7.98105 16.5667 8.80652L16.9988 8.80652C18.0608 8.80586 19.0961 9.13001 19.9533 9.73196C20.8106 10.334 21.4458 11.1827 21.7666 12.1548C22.0874 13.1269 22.0773 14.1719 21.7375 15.138C21.3978 16.1042 20.7462 16.9413 19.8772 17.5278C19.501 17.7818 18.9814 17.6949 18.7168 17.3337C18.4522 16.9725 18.5428 16.4738 18.919 16.2198C19.4983 15.8288 19.9327 15.2707 20.1592 14.6266C20.3857 13.9825 20.3925 13.2858 20.1786 12.6378C19.9647 11.9897 19.5413 11.4239 18.9697 11.0225C18.3982 10.6212 17.7082 10.4051 17.0001 10.4056H15.95C15.5697 10.4056 15.2377 10.1584 15.1432 9.80477C14.924 8.98476 14.5139 8.22317 13.9438 7.57733C13.3736 6.93149 12.6582 6.41823 11.8515 6.07619C11.0448 5.73416 10.1678 5.57226 9.28645 5.60268C8.40513 5.63311 7.54248 5.85507 6.76345 6.25184C5.98442 6.64862 5.3093 7.20988 4.78893 7.89337C4.26856 8.57686 3.91649 9.36476 3.75922 10.1978C3.60196 11.0308 3.6436 11.8872 3.88101 12.7025C4.11843 13.5178 4.54542 14.2708 5.12986 14.9048C5.4347 15.2355 5.40256 15.7408 5.05809 16.0334C4.71361 16.326 4.18723 16.2952 3.8824 15.9645C3.13098 15.1494 2.58198 14.1813 2.27674 13.133C1.97149 12.0847 1.91795 10.9836 2.12015 9.91262C2.32234 8.84161 2.77501 7.82859 3.44406 6.94981C4.11311 6.07104 4.98111 5.34942 5.98272 4.83928C6.98434 4.32914 8.09346 4.04377 9.22658 4.00465Z"
            fill={fill}
        />
    </svg>
);
export default downloadCloudIcon;
