import React from 'react';

const youtubeIcon = (
    fill: string,
    size: number,
    style?: React.CSSProperties,
) => (
    <svg
        aria-hidden="true"
        data-testid="test-icon"
        fill="none"
        height={size}
        viewBox="0 0 24 24"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
        style={style}
    >
        <title>youtube icon</title>
        <path
            d="M14.2033 4.07915L7.93592 3.85759C7.47327 3.83718 7.04672 3.95148 6.65626 4.20047L1.33936 7.52602C0.932468 7.80348 0.74288 8.26334 0.869205 8.73479C0.989515 9.18379 1.38363 9.48724 1.87474 9.52408L2.41075 9.54889C2.39593 9.67317 2.3811 9.79746 2.39475 9.93817C2.17787 10.1166 2.08689 10.4057 2.16509 10.6975C2.22525 10.922 2.39164 11.094 2.60733 11.1806L2.29198 14.3988C2.30723 14.6353 2.51852 14.7953 2.74303 14.7351L4.10236 14.7351C4.32686 14.675 4.42988 14.4308 4.32482 14.2183L3.43799 10.958C3.58149 10.7752 3.66206 10.537 3.6019 10.3125C3.53573 10.0656 3.34689 9.8996 3.1312 9.81303C3.1356 9.73966 3.15644 9.63783 3.18329 9.55845L4.52031 9.60925L4.89178 12.2528C5.10833 13.061 7.18503 13.1783 9.58719 12.5346C11.9669 11.897 13.7292 10.751 13.5126 9.94283L12.4901 7.47375L14.7386 6.07721C15.1455 5.79975 15.3576 5.33388 15.2373 4.88488C15.1109 4.41343 14.6944 4.11599 14.2033 4.07915ZM8.83073 8.45427C8.64673 8.57576 8.39978 8.64193 8.17968 8.62872L4.513 8.50436L8.09212 6.77535C8.28816 6.69876 8.36872 6.46062 8.27569 6.29305C8.1991 6.09701 7.96096 6.01645 7.79339 6.10948L3.44216 8.18974C3.29102 8.2543 3.17437 8.35774 3.0517 8.43874L2.05304 8.39352L7.24728 5.14896C7.43128 5.02747 7.67823 4.9613 7.89833 4.97451L14.025 5.20971L8.83073 8.45427Z"
            fill={fill}
        />
        <path
            d="M20.6031 10.7615C20.713 11.2771 20.8229 12.0792 20.8778 13.1104L20.9053 14.5427L20.8778 15.975C20.8229 17.0636 20.713 17.837 20.6031 18.3526C20.4932 18.6964 20.3284 18.9828 20.1086 19.212C19.8613 19.4698 19.5866 19.6417 19.2569 19.7276C18.7624 19.8708 17.6635 19.9568 15.9053 20.0141L13.4053 20.0427L10.9053 20.0141C9.14703 19.9568 8.02066 19.8708 7.55363 19.7276C7.22395 19.6417 6.92176 19.4698 6.70198 19.212C6.45472 18.9828 6.28989 18.6964 6.20747 18.3526C6.07011 17.837 5.98769 17.0636 5.93275 15.975L5.90527 14.5427C5.90527 14.1417 5.90527 13.6547 5.93275 13.1104C5.98769 12.0792 6.07011 11.2771 6.20747 10.7615C6.28989 10.4177 6.45472 10.1313 6.70198 9.87345C6.92176 9.64429 7.22395 9.47241 7.55363 9.35783C8.02066 9.24325 9.14703 9.12866 10.9053 9.07137L13.4053 9.04272L15.9053 9.07137C17.6635 9.12866 18.7624 9.24325 19.2569 9.35783C19.5866 9.47241 19.8613 9.64429 20.1086 9.87345C20.3284 10.1313 20.4932 10.4177 20.6031 10.7615ZM11.8668 16.8917L15.7954 14.5427L11.8668 12.2224V16.8917Z"
            fill={fill}
        />
    </svg>
);
export default youtubeIcon;
