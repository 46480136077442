import React from 'react';

const paperclipIcon = (
    fill: string,
    size: number,
    style?: React.CSSProperties,
) => (
    <svg
        aria-hidden="true"
        data-testid="test-icon"
        fill="none"
        height={size}
        viewBox="0 0 24 24"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
        style={style}
    >
        <title>paperclip icon</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2562 3.68063C14.5959 3.68063 13.9626 3.94643 13.4956 4.41955L5.87402 12.142C5.09585 12.9305 4.65867 13.9999 4.65867 15.115C4.65867 16.2301 5.09585 17.2995 5.87402 18.0879C6.6522 18.8764 7.70763 19.3194 8.80813 19.3194C9.90863 19.3194 10.9641 18.8764 11.7422 18.0879L18.5842 11.1554C18.9081 10.8272 19.4332 10.8272 19.7571 11.1554C20.081 11.4835 20.081 12.0156 19.7571 12.3438L12.9151 19.2763C11.8259 20.38 10.3485 21 8.80813 21C7.26772 21 5.7904 20.38 4.70116 19.2763C3.61193 18.1727 3 16.6758 3 15.115C3 13.5542 3.61193 12.0573 4.70116 10.9537L12.3228 3.23116C13.1008 2.44286 14.156 2 15.2562 2C16.3565 2 17.4117 2.44286 18.1897 3.23116C18.9677 4.01946 19.4048 5.08862 19.4048 6.20345C19.4048 7.31827 18.9677 8.38743 18.1897 9.17573L10.5598 16.8982C10.093 17.3712 9.45994 17.6369 8.79984 17.6369C8.13973 17.6369 7.50666 17.3712 7.03989 16.8982C6.57313 16.4253 6.3109 15.7838 6.3109 15.115C6.3109 14.4461 6.57313 13.8047 7.03989 13.3318L12.9116 7.38232C13.2355 7.05416 13.7606 7.05416 14.0845 7.38232C14.4083 7.71048 14.4083 8.24254 14.0845 8.57071L8.21275 14.5201C8.05705 14.6779 7.96958 14.8919 7.96958 15.115C7.96958 15.3381 8.05705 15.5521 8.21275 15.7098C8.36846 15.8676 8.57964 15.9562 8.79984 15.9562C9.02003 15.9562 9.23121 15.8676 9.38692 15.7098L17.0168 7.98735C17.4836 7.51426 17.7461 6.87239 17.7461 6.20345C17.7461 5.53435 17.4838 4.89267 17.0168 4.41955C16.5499 3.94643 15.9166 3.68063 15.2562 3.68063Z"
            fill={fill}
        />
    </svg>
);
export default paperclipIcon;
