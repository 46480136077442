import React from 'react';

const eyeIcon = (fill: string, size: number, style?: React.CSSProperties) => (
    <svg
        aria-hidden="true"
        data-testid="test-icon"
        fill="none"
        height={size}
        viewBox="0 0 24 24"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
        style={style}
    >
        <title>eye icon</title>
        <path
            d="M16 11.995C16 14.2036 14.21 15.9925 12 15.9925C9.79 15.9925 8 14.2036 8 11.995C8 11.5853 8.08 11.2055 8.19 10.8357C8.51 11.5153 9.2 11.995 10 11.995C11.1 11.995 12 11.0956 12 9.99625C12 9.19675 11.52 8.50718 10.84 8.18738C11.21 8.07745 11.59 7.9975 12 7.9975C14.21 7.9975 16 9.78638 16 11.995ZM21.94 11.6552C21.98 11.7651 22 11.8851 22 12.005C22 12.1249 21.98 12.2448 21.94 12.3548C21.8 12.7445 21.63 13.1243 21.45 13.4941C21.45 13.4941 21.45 13.4941 21.45 13.5041C19.56 17.4916 15.96 20.01 12 20.01C9.02 20.01 6.23 18.5809 4.23 16.1724C3.48 15.2929 2.87 14.2936 2.41 13.2142C2.29 12.9344 2.17 12.6446 2.06 12.3548C2.02 12.2448 2 12.1249 2 12.005C2 11.8851 2.02 11.7651 2.06 11.6552C2.17 11.3654 2.29 11.0756 2.41 10.7958C2.87 9.71643 3.48 8.72705 4.23 7.8476C6.23 5.42911 9.02 4 12 4C15.96 4 19.55 6.51843 21.45 10.5059C21.45 10.5059 21.45 10.5059 21.45 10.5159C21.63 10.8757 21.79 11.2555 21.94 11.6552ZM19.92 12.005C19.89 11.925 19.85 11.8551 19.82 11.7751C19.7 11.4853 19.56 11.2155 19.42 10.9457C19.34 10.8057 19.27 10.6558 19.19 10.5159C17.56 7.72767 14.89 5.99875 12 5.99875C11.35 5.99875 10.71 6.10868 10.09 6.27858C8.79 6.67833 7.61 7.3579 6.62 8.24735C6.55 8.30731 6.48 8.35728 6.42 8.42723C6.35 8.4872 6.28 8.56715 6.21 8.6371C5.37 9.52655 4.66 10.6159 4.13 11.8651C4.12 11.9151 4.09 11.955 4.08 12.005C4.09 12.045 4.12 12.0949 4.13 12.1349C4.65 13.3841 5.36 14.4735 6.21 15.3629C6.28 15.4329 6.34 15.5128 6.42 15.5828C6.48 15.6427 6.56 15.7027 6.62 15.7626C7.61 16.6521 8.79 17.3217 10.09 17.7314C10.71 17.9013 11.35 18.0112 12 18.0112C14.89 18.0112 17.56 16.2823 19.19 13.4941C19.27 13.3542 19.35 13.2142 19.42 13.0643C19.56 12.7945 19.69 12.5247 19.82 12.2349C19.86 12.1549 19.89 12.075 19.92 12.005Z"
            fill={fill}
        />
    </svg>
);
export default eyeIcon;
