import React from 'react';

const cartIcon = (fill: string, size: number, style?: React.CSSProperties) => (
    <svg
        aria-hidden="true"
        data-testid="test-icon"
        fill="none"
        height={size}
        viewBox="0 0 24 24"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
        style={style}
    >
        <title>cart icon</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 2.875C0 2.39175 0.391751 2 0.875 2H4.375C4.79205 2 5.15113 2.29434 5.23298 2.70327L5.96788 6.375H20.125C20.3858 6.375 20.6329 6.49131 20.7992 6.69223C20.9654 6.89316 21.0334 7.15775 20.9845 7.41391L19.5833 14.7617C19.4632 15.366 19.1345 15.9088 18.6546 16.2951C18.1771 16.6795 17.5802 16.8846 16.9674 16.875H8.47756C7.86483 16.8846 7.26793 16.6795 6.79042 16.2951C6.31071 15.9089 5.98205 15.3664 5.86191 14.7625C5.86186 14.7622 5.86196 14.7627 5.86191 14.7625L4.40019 7.4593C4.39426 7.43514 4.38933 7.41059 4.38546 7.38569L3.65778 3.75H0.875C0.391751 3.75 0 3.35825 0 2.875ZM6.31814 8.125L7.57823 14.4208C7.61824 14.6222 7.72782 14.8031 7.88778 14.9319C8.04775 15.0607 8.24791 15.1291 8.45323 15.1252L8.47 15.125H16.975L16.9918 15.1252C17.1971 15.1291 17.3972 15.0607 17.5572 14.9319C17.7164 14.8037 17.8257 14.6239 17.8662 14.4236L19.0674 8.125H6.31814ZM6.125 20.375C6.125 19.4085 6.9085 18.625 7.875 18.625C8.8415 18.625 9.625 19.4085 9.625 20.375C9.625 21.3415 8.8415 22.125 7.875 22.125C6.9085 22.125 6.125 21.3415 6.125 20.375ZM15.75 20.375C15.75 19.4085 16.5335 18.625 17.5 18.625C18.4665 18.625 19.25 19.4085 19.25 20.375C19.25 21.3415 18.4665 22.125 17.5 22.125C16.5335 22.125 15.75 21.3415 15.75 20.375Z"
            fill={fill}
        />
    </svg>
);
export default cartIcon;
