import React from 'react';

const creditCardIcon = (
    fill: string,
    size: number,
    style?: React.CSSProperties,
) => (
    <svg
        aria-hidden="true"
        data-testid="test-icon"
        fill="none"
        height={size}
        viewBox="0 0 24 24"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
        style={style}
    >
        <title>credit card icon</title>
        <path
            d="M20.2986 4H3.66667C2.72917 4 2 4.78571 2 5.71429V18.2857C2 19.25 2.72917 20 3.66667 20H20.2986C21.2361 20 22 19.25 22 18.2857V5.71429C22 4.78571 21.2361 4 20.2986 4ZM3.875 5.71429H20.0903C20.2292 5.71429 20.2986 5.82143 20.2986 5.92857V8H3.66667V5.92857C3.66667 5.82143 3.73611 5.71429 3.875 5.71429ZM20.0903 18.2857H3.875C3.73611 18.2857 3.66667 18.2143 3.66667 18.0714V10H20.2986V18.0714C20.2986 18.2143 20.2292 18.2857 20.0903 18.2857ZM8.66667 15C8.66667 14.7857 8.45833 14.5714 8.25 14.5714H5.75C5.50694 14.5714 5.33333 14.7857 5.33333 15V16.1429C5.33333 16.3929 5.50694 16.5714 5.75 16.5714H8.25C8.45833 16.5714 8.66667 16.3929 8.66667 16.1429V15ZM15.3333 15C15.3333 14.7857 15.125 14.5714 14.9167 14.5714H10.1944C9.95139 14.5714 9.77778 14.7857 9.77778 15V16.1429C9.77778 16.3929 9.95139 16.5714 10.1944 16.5714H14.9167C15.125 16.5714 15.3333 16.3929 15.3333 16.1429V15Z"
            fill={fill}
        />
    </svg>
);
export default creditCardIcon;
